export default {
  // Endpoints
  loginEndpoint: 'cp/signin',
  registerEndpoint: 'cp/signup',
  refreshEndpoint: 'cp/refresh-token',
  forgotEndpoint: 'cp/forgot-password',
  resetEndpoint: 'cp/change-password',
  logoutEndpoint: 'cp/logout',
  verifyLoginEndpoint: 'cp/change-password',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageUserDataKeyName: 'userData',
}
